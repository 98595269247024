import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import LiveNoteIcon from "../../assets/icons/notes.svg";
import DashboardIcon from "../../assets/icons/dashboard.svg";
import PlannerIcon from "../../assets/icons/planner.svg";
import ProgramIcon from "../../assets/icons/program.svg";
import ForumIcon from "../../assets/icons/forums.svg";
import ChatLock from "../../assets/icons/ChatLock.svg";
import RewardIcon from "../../assets/icons/rewards.svg";
import SettingIcon from "../../assets/icons/setting.svg";
import UserRequest from "../../assets/icons/userRequest.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import { logout } from "../../actions/userActions";
import { ACCESS_TOKEN, USER } from "../../constantsNew";
import { setAuthenticated, setCurrentUser } from "../../actions/postActions";
import { Tooltip } from "react-bootstrap";
import { MdOutlineStorefront } from "react-icons/md";
import withRoleValidation from "../../util/withRoleValidation";

const Sidebar = (props) => {
  // const [collapsed, setCollapsed] = useState(false);
  const { sidebarCollapsed, toggleSidebar, currentRoleView } = props;
  const activeClass = "bg-[#122259] rounded-[7px]";
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const currentUser = useSelector((state) => state.users.currentUser);
  console.log("currentUser", currentUser);
  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER);
    dispatch(setCurrentUser(null));
    dispatch(setAuthenticated(true));
    navigate("/login");
  }

  // const toggleSidebar = () => {
  //   let collapsed = localStorage.setItem("colapsed",true)
  //   setCollapsed(!collapsed);
  //   localStorage.setItem("colapsed",true)
  // };

  return (
    <div
      className={`sidebar pt-6 px-4 pb-12 h-[100vh] overflow-y-scroll scrollbar-hide bg-gradient-to-b from-[#2B60EC] to-[#8535FF] transition-width duration-300`}
      style={{
        width: sidebarCollapsed ? "85px" : "250px",
        position: "relative",
      }}
    >
      <div className="">
        
        {sidebarCollapsed ? <h3
          className={`text-white hidden lg:block text-[2rem] font-[400] text-start transition-opacity duration-300"
          }`}
        ><strong>SC</strong></h3> : 
        <h3
          className={`text-white hidden lg:block text-[2rem] font-[400] text-start transition-opacity duration-300 
             opacity-100 w-full"
          }`}
        >
          Stridecal
        </h3>
        }
      </div>
      <div className="h-3/4 overflow-y-auto overflow-x-hidden">
        {/* {(currentRoleView == "ROlE_MODERATOR" ||
          currentRoleView == "ROLE_USER" ||
          currentRoleView == "ROLE_STUDENT" ||
          currentRoleView == "ROLE_TEACHER" ||
          currentRoleView == "ROLE_PARENT") && ( */}
        <div
          className={
            splitLocation[1] === "" || splitLocation[1] === "livenotes"
              ? activeClass
              : ""
          }
        >
          <Link to="/livenotes">
            <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
              <img
                className="w-[25px]"
                src={LiveNoteIcon}
                alt="live note icon"
                title="Live Notes"
              />

              <p
                className={`text-white whitespace-nowrap  ${
                  sidebarCollapsed && "opacity-0"
                } lg:block text-[.9rem]`}
              >
                Live Notes
              </p>
            </div>
          </Link>
        </div>
        {/* )} */}
        <div className={splitLocation[1] === "dashboard" ? activeClass : ""}>
          <Link to="/dashboard">
            <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
              <img
                className="w-[25px]"
                src={DashboardIcon}
                alt="dashboard icon"
                title="Dashboard"
              />

              <p
                className={`text-white  whitespace-nowrap  ${
                  sidebarCollapsed && "opacity-0"
                } lg:block text-[.9rem]`}
              >
                Dashboard
              </p>
            </div>
          </Link>
        </div>
        <div className={splitLocation[1] === "planner" ? activeClass : ""}>
          <Link to="/planner">
            <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
              <img
                className="w-[25px]"
                src={PlannerIcon}
                alt="planner icon"
                title="Planner"
              />

              <p
                className={`text-white whitespace-nowrap  ${
                  sidebarCollapsed && "opacity-0"
                } lg:block text-[.9rem]`}
              >
                Planner
              </p>
            </div>
          </Link>
        </div>
        <div className={splitLocation[1] === "program" ? activeClass : ""}>
          <Link to="/program">
            <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
              <img
                className="w-[25px]"
                src={ProgramIcon}
                alt="program icon"
                title="My Projects"
              />

              <p
                className={`text-white ${
                  sidebarCollapsed && "opacity-0"
                } lg:block text-[.9rem]`}
              >
                My Projects
              </p>
            </div>
          </Link>
        </div>
        <div className={splitLocation[1] === "forum" ? activeClass : ""}>
          <Link to="/forum">
            <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
              <img
                className="w-[25px]"
                src={ForumIcon}
                alt="forum icon"
                title="Forum"
              />

              <p
                className={`text-white whitespace-nowrap ${
                  sidebarCollapsed && "opacity-0"
                } lg:block text-[.9rem]`}
              >
                Forum
              </p>
            </div>
          </Link>
        </div>
        {/* <div className={splitLocation[1] === "reward" ? activeClass : ""}>
          <Link to="/reward">
            <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
              <img
                className="w-[25px]"
                src={RewardIcon}
                alt="reward icon"
                title="Reward"
              />
              
                <p className={`text-white whitespace-nowrap ${sidebarCollapsed && 'opacity-0'} lg:block text-[.9rem]`}>
                  Reward
                </p>
              
            </div>
          </Link>
        </div> */}
        {currentUser?.userRoles.includes("ROLE_TEACHER") ||
          currentUser?.userRoles.includes("ROLE_PARENT") ||
          currentUser?.userRoles.includes("ROLE_MODERATOR") ||
          (currentUser?.userRoles.includes("ROLE_ORG_ADMIN") && (
            <div
              className={
                splitLocation[1] === "academic-programs" ? activeClass : ""
              }
            >
              <Link to="/academic-programs">
                <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
                  <img
                    className="w-[25px]"
                    src={ProgramIcon}
                    alt="acad programs icon"
                    title="Academic Programs"
                  />

                  <p
                    className={`text-white whitespace-nowrap  ${
                      sidebarCollapsed && "opacity-0"
                    } lg:block text-[.9rem]`}
                  >
                    Academic Programs
                  </p>
                </div>
              </Link>
            </div>
          ))}
        {(currentUser?.userRoles.includes("ROLE_USER") ||
          currentUser?.userRoles.includes("ROLE_STUDENT")) && (
          <div
            className={splitLocation[1] === "marketplace" ? activeClass : ""}
          >
            <Link to="/marketplace">
              <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
                {/* <img
                className="w-[25px]"
                src={<MdOutlineStorefront/>}
                alt="acad programs icon"
                title="Academic Programs"
              /> */}
                <span className="w-[25px]">
                  <MdOutlineStorefront color="white" size={25} />
                </span>
                <p
                  className={`text-white whitespace-nowrap  ${
                    sidebarCollapsed && "opacity-0"
                  } lg:block text-[.9rem]`}
                >
                  Marketplace
                </p>
              </div>
            </Link>
          </div>
        )}

        {(currentUser?.userRoles.includes("ROLE_ADMIN") ||
          currentUser?.userRoles.includes("ROLE_TEACHER") ||
          currentUser?.userRoles.includes("ROLE_MODERATOR") ||
          currentUser?.userRoles.includes("ROLE_ORG_ADMIN") ||
          currentUser?.userRoles.includes("ROLE_SUPER_ADMIN")) &&
          !currentUser?.userRoles.includes("ROLE_STUDENT") && (
            <div
              className={
                splitLocation[1] === "administration" ? activeClass : ""
              }
            >
              <Link to="/administration">
                <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
                  <img
                    className="w-[25px]"
                    src={UserRequest}
                    alt="Chat Lock icon"
                    title="Administration"
                  />

                  <p
                    className={`text-white whitespace-nowrap ${
                      sidebarCollapsed && "opacity-0"
                    } lg:block text-[.9rem]`}
                  >
                    Administration
                  </p>
                </div>
              </Link>
            </div>
          )}
        {(currentUser?.userRoles.includes("ROLE_STUDENT") ||
          currentUser?.userRoles.includes("ROLE_USER")) &&
          !currentUser?.userRoles.includes("ROLE_ADMIN") &&
          !currentUser?.userRoles.includes("ROLE_TEACHER") &&
          !currentUser?.userRoles.includes("ROLE_MODERATOR") &&
          !currentUser?.userRoles.includes("ROLE_ORG_ADMIN") &&
          !currentUser?.userRoles.includes("ROLE_SUPER_ADMIN") && (
            <div
              className={
                splitLocation[1] === "administration" ? activeClass : ""
              }
            >
              <Link to="/my-requests">
                <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
                  <img
                    className="w-[25px]"
                    src={UserRequest}
                    alt="Chat Lock icon"
                    title="Administration"
                  />

                  <p
                    className={`text-white whitespace-nowrap ${
                      sidebarCollapsed && "opacity-0"
                    } lg:block text-[.9rem]`}
                  >
                    My Requests
                  </p>
                </div>
              </Link>
            </div>
          )}
      </div>
      <div
        className="h-1/6"
        style={{  width: "100%" }}
      >
        <div className={splitLocation[1] === "setting" ? activeClass : ""}>
          <Link to="/settings">
            <div className="py-3 my-1 px-3 flex items-center justify-start gap-4">
              <img
                className="w-[25px]"
                src={SettingIcon}
                alt="setting icon"
                title="Settings"
              />

              <p
                className={`text-white whitespace-nowrap ${
                  sidebarCollapsed && "opacity-0"
                } lg:block text-[.9rem]`}
              >
                Settings
              </p>
            </div>
          </Link>
        </div>
        <div
          onClick={handleLogout}
          className="py-3 my-1 px-3 flex cursor-pointer items-center justify-start gap-4"
        >
          <img
            className="w-[25px]"
            src={LogoutIcon}
            alt="logout icon"
            title="Logout"
          />

          <p
            className={`text-white whitespace-nowrap  ${
              sidebarCollapsed && "opacity-0"
            } lg:block text-[.9rem]`}
          >
            Logout
          </p>
        </div>
        <Tooltip title={sidebarCollapsed ? "Expand" : "Collapse"}>
          <div
            className={`flex ${
              sidebarCollapsed ? "justify-center" : "justify-end"
            }`}
          >
            <button onClick={toggleSidebar} className="text-white">
              {sidebarCollapsed ? (
                <FiChevronRight size={24} />
              ) : (
                <FiChevronLeft size={24} />
              )}
            </button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Sidebar;

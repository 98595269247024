import React, { useEffect, useState } from "react";
import {
  FaPlus,
  FaSpinner,
  FaTimes,
  FaBuilding,
  FaMapMarkerAlt,
} from "react-icons/fa";
import SearchIcon from "../../assets/icons/search.svg";
import { Input, Select, Skeleton, Empty } from "antd";
import Modal from "react-responsive-modal";
import { get, post } from "../../util/APIUtils";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
const { Option } = Select;

export const OrganizationSettings = () => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [noUserFound, setNoUserFound] = useState(false);
  const [organisationTypes, setOrganisationTypes] = useState([]);
  const [form, setForm] = useState({
    organisationType: "",
    organisationName: "",
    address: "",
    registrationNo: ""
  });
  const [formErrors, setFormErrors] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsLoading, setOrganizationsLoading] = useState(false);

  const { currentUser } = useSelector((state) => state.users);
  const cancelDelete = () => {
    setOpenCreateModal(false);
  };

  const CreateOrganisationRequest = async () => {
    try {
      const response = await post(`/stridecal-backend/api/workflow-requests`, {
        requestorId: currentUser.id,
        requestorName: `${currentUser.firstName} ${currentUser.lastName}`,
        requestType: "CreateOrganization",
        requestDescription: JSON.stringify(form),
        isApproved: false,
      });
      if (response) {
        await fetchOrganizations();
      }
    } catch (error) {
      console.log("Error Creating Organization Request");
    }
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        if (inputValue) {
          setNoUserFound(true);
        }
      }, 3000);
    }
  }, [loading, inputValue]);

  useEffect(() => {
    fetchOrganizations();
    fetchOrganisationTypes();
  }, []);

  const fetchOrganizations = async () => {
    setOrganizationsLoading(true);
    try {
      const response = await get("/organization-service/api/organizations");
      if (Array.isArray(response)) {
        setOrganizations(response);
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error Fetching Organizations:", error);
    } finally {
      setOrganizationsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganizations();
    fetchOrganisationTypes();
  }, []);

  const fetchOrganisationTypes = async () => {
    try {
      const response = await get(
        `/organization-service/api/organizations/search/types`
      );
      if (response) {
        setOrganisationTypes(response);
      }
    } catch (error) {
      console.log("Error Fetching OrganisationTypes");
    }
  };

  // Debounce function
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  // Search organizations
  const searchOrganizations = async (searchTerm) => {
    if (searchTerm.length >= 3) {
      setOrganizationsLoading(true);
      try {
        const response = await get(
          `/organization-service/api/organizations/search/findByName?name=${searchTerm}`
        );
        if (Array.isArray(response)) {
          setOrganizations(response);
        } else {
          console.error("Invalid response format:", response);
          setOrganizations([]);
        }
      } catch (error) {
        console.error("Error searching organizations:", error);
        setOrganizations([]);
      } finally {
        setOrganizationsLoading(false);
      }
    }
  };

  // Debounced search function
  const debouncedSearch = React.useCallback(
    debounce((searchTerm) => searchOrganizations(searchTerm), 500),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length >= 3) {
      setLoading(true);
      debouncedSearch(value);
    } else if (value.length === 0) {
      // Reset to show all organizations when search is cleared
      fetchOrganizations();
    }
  };

  const handleClear = () => {
    setInputValue("");
    fetchOrganizations(); // Reset to show all organizations
  };

  const handleFieldChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const filteredOrganizations = organizations.filter(
    (org) =>
      !inputValue ||
      org.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      org.organizationType.toLowerCase().includes(inputValue.toLowerCase())
  );

  const [skeletonCount, setSkeletonCount] = useState(0);

  useEffect(() => {
    const calculateSkeletonCount = () => {
      const screenHeight = window.innerHeight;
      const skeletonHeight = 200;
      const skeletonsPerRow = 3;
      const rows = Math.ceil(screenHeight / skeletonHeight);
      setSkeletonCount(rows * skeletonsPerRow);
    };
    calculateSkeletonCount();
    window.addEventListener("resize", calculateSkeletonCount);
    return () => window.removeEventListener("resize", calculateSkeletonCount);
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!form.organisationType) errors.organisationType = "Type is required.";
    if (!form.organisationName) errors.organisationName = "Name is required.";
    if (!form.address) errors.address = "Address is required.";
    if (!form.registrationNo) errors.registrationNo = "Registration No is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      setSaveLoading(true);
      try {
        await CreateOrganisationRequest();
        setOpenCreateModal(false);
        await fetchOrganizations();
      } catch (error) {
        console.error("Error creating organization:", error);
      } finally {
        setSaveLoading(false);
      }
    }
  };

  const OrganizationCard = ({ organization }) => (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
      <div className="flex items-center mb-4">
        <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
          <FaBuilding className="text-blue-500 text-xl" />
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-semibold text-gray-800">
            {organization.name}
          </h3>
          <p className="text-sm text-gray-500">
            {organization.organizationType}
          </p>
        </div>
      </div>
      <div className="flex items-start mt-4">
        <FaMapMarkerAlt className="text-gray-400 mt-1 mr-2" />
        <p className="text-gray-600">{organization.address}</p>
      </div>
      {organization.departments?.length > 0 && (
        <div className="mt-4">
          <p className="text-sm font-medium text-gray-500">Departments:</p>
          <div className="flex flex-wrap gap-2 mt-2">
            {organization.departments.map((dept, index) => (
              <span
                key={index}
                className="px-2 py-1 text-xs bg-gray-100 text-gray-600 rounded-full"
              >
                {dept}
              </span>
            ))}
          </div>
        </div>
      )}
      {organization.employees?.length > 0 && (
        <div className="mt-4">
          <p className="text-sm font-medium text-gray-500">
            Employees: {organization.employees.length}
          </p>
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-white h-[78vh] overflow-y-scroll rounded-lg col-span-1 py-6 px-6">
      <h4 className="font-[600] text-start text-[1.3rem]">
        Organization Settings
      </h4>
      <div className="grid grid-cols-2 my-2 mt-4 justify-between w-full">
        <div className="relative col-span-1 flex items-center rounded-lg bg-[#FAFAFA]">
          <img className="w-[15px] ml-3" src={SearchIcon} alt="search-icon" />
          <input
            value={inputValue}
            onChange={handleInputChange}
            className="py-3 px-4 flex-grow outline-none ml-2 bg-[#FAFAFA]"
            placeholder="Search Organization (min. 3 characters)"
          />
          {inputValue && (
            <button onClick={handleClear} className="ml-2 text-gray-500 pr-3">
              <FaTimes />
            </button>
          )}
          {loading && inputValue.length >= 3 && (
            <FaSpinner className="animate-spin mr-3 text-gray-500" />
          )}
        </div>
        <div className="flex col-span-1 justify-end py-3 px-12 ">
          <button
            onClick={() => setOpenCreateModal(true)}
            className="flex items-center gap-1 justify-center text-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            <FaPlus /> Create Organisation
          </button>
        </div>
      </div>
      {organizationsLoading ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 p-4">
          {Array.from({ length: 6 }).map((_, index) => (
            <Skeleton key={index} active className="h-[200px]" />
          ))}
        </div>
      ) : organizations.length > 0 ? (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 p-4">
          {organizations.map((org, index) => (
            <OrganizationCard key={index} organization={org} />
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <Empty
            description={
              inputValue.length > 0 && inputValue.length < 3
                ? "Please enter at least 3 characters to search"
                : "No organizations found"
            }
          />
        </div>
      )}
      <Modal
        open={openCreateModal}
        onClose={cancelDelete}
        center
        styles={{
          modal: {
            width: "900px",
            padding: "40px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          },
          closeButton: {
            outline: "none",
            cursor: "pointer",
          },
        }}
      >
        <h2 className="text-lg font-semibold mb-4 text-center">
          Create Organisation
        </h2>
        <div className="flex flex-row gap-2 px-4 py-2 rounded-lg w-full  p-2 items-center justify-center  mb-2">
          <label className="whitespace-nowrap text-sm">
            Organisation Type:
          </label>
          <Select
            name="organisationType"
            value={form.organisationType}
            onChange={(value) => handleFieldChange("organisationType", value)}
            className="mr-2 w-full"
          >
            {organisationTypes.map((type, index) => (
              <Option key={index} value={type}>
                {type}
              </Option>
            ))}
          </Select>
          {formErrors.organisationType && (
            <div className="text-red-500 text-sm">
              {formErrors.organisationType}
            </div>
          )}
        </div>

        <div className="flex flex-row gap-2 px-4 py-2 rounded-lg w-full  p-2 items-center justify-center mb-2">
          <label className="whitespace-nowrap text-sm">
            Organisation Name:
          </label>
          <Input
            name="organisationName"
            value={form.organisationName}
            onChange={(e) =>
              handleFieldChange("organisationName", e.target.value)
            }
            className="mr-2 w-full"
          />
          {formErrors.organisationName && (
            <div className="text-red-500 text-sm">
              {formErrors.organisationName}
            </div>
          )}
        </div>
        <div className="flex flex-row gap-2 px-4 py-2 rounded-lg w-full  p-2 items-center justify-center mb-2">
          <label className="whitespace-nowrap text-sm">
            Registration Number:
          </label>
          <Input
            name="registrationNo"
            value={form.registrationNo}
            onChange={(e) =>
              handleFieldChange("registrationNo", e.target.value)
            }
            className="mr-2 w-full"
          />
          {formErrors.registrationNo && (
            <div className="text-red-500 text-sm">
              {formErrors.registrationNo}
            </div>
          )}
        </div>

        <div className="flex flex-row gap-2 px-4 py-2 rounded-lg w-full  p-2 items-start justify-center  mb-2">
          <label className="whitespace-nowrap text-sm">Address:</label>
          <TextArea
            name="address"
            value={form.address}
            onChange={(e) => handleFieldChange("address", e.target.value)}
            className="mr-2 w-full"
          />
          {formErrors.address && (
            <div className="text-red-500 text-sm">{formErrors.address}</div>
          )}
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={cancelDelete}
            className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
            disabled={saveLoading}
          >
            {saveLoading ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Saving...
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Modal>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Table, Button, Pagination } from "antd";
import { AiOutlineCheck, AiOutlineDelete, AiOutlineX } from "react-icons/ai";
import { del, get, patch, post, put } from "../../util/APIUtils";
import { useSelector } from "react-redux";
import { Confirm } from "react-st-modal";
import moment from "moment";

const Approvals = () => {
  const { currentUser } = useSelector((state) => state.users);
  const userTimezone = useSelector((state) => state.users.userTimezone);
  console.log("User Timezone===>",userTimezone)
  const [wfReqList, setWfReqList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalElement, setTotalElement] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser?.id) {
      fetchWfRequests(currentUser.id);
    }
  }, [currentUser, currentPage]);

  const fetchWfRequests = async (reqId) => {
    setLoading(true);
    try {
      const response = await get(
        `/stridecal-backend/api/workflow-requests?page=${currentPage - 1}`
      );
      if (response) {
        const transformedData =
          response.content.length > 0
            ? response.content.map((item, index) => ({
                key: item.id || index,
                name: item.requestorName,
                email: item.requestorName,
                requestDate: userTimezone ? moment(item.createdDate).tz(userTimezone.timeZone).format("DD MMM, YYYY hh:mm:ss a"):  moment(item.createdDate).local().format("DD MMM, YYYY hh:mm:ss a"),
                // requestDate: item.createdDate,
                requestType: formatRequestType(
                  item.requestType,
                  item.requestDescription
                ),
                requestorId: item.requestorId,
                status: item.isApproved ? "Approved" : "Pending",
                rawData: item,
              }))
            : [];
        setWfReqList(transformedData);
        setPageSize(response.pageable.pageSize);
        setCurrentPage(response.pageable.pageNumber + 1);
        setTotalElement(response.totalElements);
      }
    } catch (error) {
      console.error("Error Fetching Workflow Requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatRequestType = (type, description) => {
    try {
      const descObj = JSON.parse(description);
      switch (type) {
        case "CreateOrganization":
          return `Create ${descObj.organisationType} - ${descObj.name}`;
        default:
          return type;
      }
    } catch (e) {
      return type;
    }
  };

  // const transformToOrganizationData = (rawData) => {
  //   try {
  //     const description = JSON.parse(rawData.requestDescription);

  //     const organizationType = description.organisationType
  //       .toUpperCase()
  //       .replace(" ", "_");

  //     return {
  //       organizationType: organizationType,
  //       name: description.organisationName,
  //       address: description.address,
  //       departments: [],
  //       employees: [
  //         {
  //           id: rawData.requeustorId,
  //           name: rawData.requestorName,
  //           position: "ADMIN",
  //         },
  //       ],
  //     };
  //   } catch (error) {
  //     console.error("Error transforming organization data:", error);
  //     throw new Error("Invalid request data format");
  //   }
  // };

  const handleApprove = async (record) => {
    const confirmDelete = await Confirm(
      `Are you sure you want to approve the request?`,
      "Approve"
    );

    if (confirmDelete) {
      try {
        // const organizationData = transformToOrganizationData(record.rawData);

        // const orgResponse = await post(
        //   "/organization-service/api/organizations",
        //   organizationData
        // );

        // if (orgResponse) {
        const updateData = {
          // ...record.rawData,
          isApproved: true,
          // approvalStatusChangeDate: new Date().toISOString(),
          // lastUpdatedDate: new Date().toISOString(),
        };

        await patch(
          `/stridecal-backend/api/workflow-requests/${record.rawData.id}`,
          updateData
        );

        setWfReqList((prevList) =>
          prevList.map((item) =>
            item.key === record.key ? { ...item, status: "Approved" } : item
          )
        );
        // }
      } catch (error) {
        console.error("Error approving request:", error);
      }
    }
  };

  const handleDelete = async (record) => {
    const confirmDelete = await Confirm(
      `Are you sure you want to remove the request?`,
      "Confirm Delete"
    );

    if (confirmDelete) {
      try {
        await del(
          `/stridecal-backend/api/workflow-requests/${record.rawData.id}`
        );

        setWfReqList((prevList) =>
          prevList.filter((item) => item.key !== record.key)
        );
      } catch (error) {
        console.error("Error approving request:", error);
      }
    }
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span className="font-semibold text-gray-700">{text}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span className="text-gray-600">{text}</span>,
    },
    {
      title: "Request Date",
      dataIndex: "requestDate",
      key: "requestDate",
    },
    {
      title: "Request Type",
      dataIndex: "requestType",
      key: "requestType",
      render: (text) => (
        <span className="px-2 py-1 text-sm font-medium text-blue-700 bg-blue-100 rounded-full">
          {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          className={`px-2 py-1 text-sm font-medium rounded-full ${
            text === "Approved"
              ? "text-green-700 bg-green-100"
              : "text-yellow-700 bg-yellow-100"
          }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="flex space-x-2">
          {record.status !== "Approved" ? (
            <>
              {record.requestorId == currentUser.id ? (
                <Button
                  type="primary"
                  icon={<AiOutlineDelete className="mr-1" />}
                  className="flex items-center bg-green-500 hover:bg-green-600 border-none"
                  onClick={() => handleDelete(record)}
                >
                  Delete
                </Button>
              ) : (
                <Button
                  type="primary"
                  icon={<AiOutlineCheck className="mr-1" />}
                  className="flex items-center bg-green-500 hover:bg-green-600 border-none"
                  onClick={() => handleApprove(record)}
                >
                  Approve
                </Button>
              )}
            </>
          ) : (
            <span>Approved</span>
          )}
          {/*
          <Button
            danger
            icon={<AiOutlineClose className="mr-1" />}
            className="flex items-center"
            onClick={() => handleDisapprove(record)}
          >
            Disapprove
          </Button>
          */}
        </div>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div className="container h-[78vh] mx-auto p-6 bg-white rounded-lg shadow-lg overflow-y-auto">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">
        Request Approvals
      </h2>
      <Table
        columns={columns}
        dataSource={wfReqList}
        pagination={false}
        loading={loading}
        rowClassName="bg-gray-50 hover:bg-gray-100 transition duration-200 ease-in-out"
        className="mb-4"
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalElement}
        onChange={handlePageChange}
        showSizeChanger
        pageSizeOptions={["5", "10", "20", "50"]}
        className="flex justify-end"
      />
    </div>
  );
};

export default Approvals;

import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { get } from "./APIUtils";

const withRoleValidation = (WrappedComponent) => {
  return function RoleValidationHOC(props) {
    const { currentRole } = props;
    const [loading, setLoading] = useState(true);
    const [isRoleValid, setIsRoleValid] = useState(false);

    const validateRole = async () => {
      setLoading(true);
      try {
        const response = await get(`/stridecal-backend/api/user/me`);

        if (response.userRole.includes(currentRole)) {
          setIsRoleValid(true);
        } else {
          setIsRoleValid(false);
        }
      } catch (error) {
        console.error("Error validating role:", error);
        setIsRoleValid(false);
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
      if (currentRole) {
        validateRole();
      } else {
        setIsRoleValid(false);
        setLoading(false);
      }
    }, [currentRole]);

    if (loading) {
      return <Spin size="large" />;
    }

    if (!isRoleValid) {
      return <div>Unauthorized role: {currentRole}</div>;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withRoleValidation;

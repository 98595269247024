import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatMessages,
  sendMessage,
  fetchChatSearchedUser,
  CLEAR_CHAT_SEARCHED_USERS,
  addParticipant,
  toggleAddParticipantPopup,
  renameChat,
  deleteMessage,
  fetchChats,
  pinMessage,
  unpinMessage,
  editMessage,
} from "../../actions/chatActions";
import { MoreHorizontal, Pin } from "lucide-react";
import Input from "./Input";
import DefaultUserIcon from "../../assets/default-user-icon.png";
import AddParticipant from "../../assets/icons/AddParticipant.svg";
import ChatLock from "../../assets/icons/ChatLock.svg";
import AuthImage from "../../components/AuthImage/AuthImage";
import moment from "moment";
import debounce from "lodash/debounce";
import SearchIcon from "../../assets/icons/search.svg";
import PlusIcon from "../../assets/icons/Plus.svg";
import ParticipantsList from "./ParticipantsList";
import MessageActions from "./MessageActions";
import { del, get, post } from "../../util/APIUtils";
import ForumWelcomePlaceholder from "./ForumWelcomePlaceholder";
import PinnedMessagesDropdown from "./PinnedMessagesPopup";
import EditMessageComponent from "./EditMessageComponent";
import Modal from "react-responsive-modal";
import { FaCross } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { message } from "antd";

const ChattingArea = () => {
  const dispatch = useDispatch();
  const currentChatId = useSelector((state) => state.chat.currentChatId);
  const chats = useSelector((state) => state.chat.chats || []);
  const currentUser = useSelector((state) => state.users.currentUser);
  const typingStatus = useSelector((state) => state.chat.typingStatus);
  const messages = useSelector(
    (state) => state.chat.messages[currentChatId] || []
  );
  const chatSearchedUsers = useSelector(
    (state) => state.chat.chatSearchedUsers
  );
  const showAddParticipantPopup = useSelector(
    (state) => state.chat.showAddParticipantPopup
  );
  const [searchInputValue, setSearchInputValue] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const popupRef = useRef(null);
  const chatArea = useRef(null);
  const hiddenScroll = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newChatName, setNewChatName] = useState("");
  const renameInputRef = useRef(null);
  const [messageGroups, setMessageGroups] = useState({});
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [forwardDialogOpen, setForwardDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [forwardParticipant, setForwardParticipant] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();
  const messageRefs = useRef({});
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);

  const addMessageRef = (id, ref) => {
    if (ref) {
      messageRefs.current[id] = ref;
    }
  };

  const debouncedFilterUsers = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        dispatch(fetchChatSearchedUser(value));
      } else {
        dispatch({ type: CLEAR_CHAT_SEARCHED_USERS });
      }
    }, 300),
    [dispatch]
  );

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInputValue(value);
    debouncedFilterUsers(value);
  };

  const handleAddParticipant = async (user) => {
    if (currentChatId) {
      await dispatch(addParticipant(currentChatId, user.id));
      setSearchInputValue("");
      dispatch({ type: CLEAR_CHAT_SEARCHED_USERS });
      dispatch(toggleAddParticipantPopup(false));
    }
  };

  useEffect(() => {
    if (currentChatId) {
      dispatch(fetchChatMessages(currentChatId));
    }
  }, [dispatch, currentChatId]);

  useEffect(() => {
    setTimeout(() => {
      hiddenScroll.current?.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  }, [messages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        dispatch(toggleAddParticipantPopup(false));
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const handleSendMessage = (text, imageUrl) => {
    if ((text || imageUrl) && currentChatId) {
      dispatch(sendMessage(currentChatId, text, imageUrl));
    }
  };

  const handleAddParticipantClick = () => {
    dispatch(toggleAddParticipantPopup(!showAddParticipantPopup));
  };

  const handleDeleteMessage = async (messageId) => {
    await dispatch(deleteMessage(messageId, currentChatId));
    dispatch(fetchChats());
  };

  const handleEditMessage = (messageId) => {
    const message = messages.find((m) => m.id === messageId);
    if (message) {
      setEditingMessageId(messageId);
      setEditingText(message.text);
    }
  };

  const handleEditSubmit = async (messageId) => {
    if (editingText.trim() && currentChatId) {
      try {
        await dispatch(
          editMessage(currentChatId, messageId, editingText.trim())
        );
        setEditingMessageId(null);
        setEditingText("");
      } catch (error) {
        console.error("Error editing message:", error);
      }
    }
  };

  const handleEditCancel = () => {
    setEditingMessageId(null);
    setEditingText("");
  };

  const handlePinMessage = async (messageId) => {
    if (currentChatId) {
      await dispatch(pinMessage(currentChatId, messageId));
      dispatch(fetchChatMessages(currentChatId));
    }
  };

  const handleUnpinMessage = async (messageId) => {
    if (currentChatId) {
      await dispatch(unpinMessage(currentChatId, messageId));
      dispatch(fetchChatMessages(currentChatId));
    }
  };

  const handleCopyMessage = (message) => {
    // Create a temporary text area to copy the message
    const textarea = document.createElement("textarea");
    textarea.value = message.text || "";
    document.body.appendChild(textarea);
    textarea.select();

    try {
      document.execCommand("copy");
      // You could add a toast notification here to indicate successful copy
    } catch (err) {
      console.error("Failed to copy message:", err);
    } finally {
      document.body.removeChild(textarea);
    }
  };

  const handleForwardMessage = (messageId) => {
    setSelectedUser(null);
    setSearchInputValue("");
    setSelectedMessage(messageId);
    setForwardDialogOpen(true);
    console.log("Forward message:", messageId, forwardDialogOpen);
  };

  const handleForwardSubmit = async () => {
    if (forwardParticipant.length > 0) {
      try {
        await post(
          `/chat-service/ui/v1/chats/${currentChatId}/messages/forward`,
          {
            messageIds: [selectedMessage],
            targetUserIds: [...forwardParticipant.map((obj) => obj.id)],
            authorId: currentUser.id,
            unread: true,
            // deliveryStatus: "SENT",
          }
        );
        await dispatch(fetchChats());
        setForwardDialogOpen(false);
        setSearchInputValue("");
        setForwardParticipant([]);
      } catch (error) {
        console.error("Error forwarding message:", error);
        message.error("Failed to forward message.");
      }
    }
  };

  const handleForwardParticipant = (participant) => {
    console.log("participant:", participant);
    setForwardParticipant([...forwardParticipant, participant]);
  };

  const handleRemoveForwardParticipant = (participant) => {
    setForwardParticipant((prevParticipants) =>
      prevParticipants.filter((p) => p.id !== participant.id)
    );
  };

  const handleReplyMessage = (messageId) => {
    // Implement reply logic
    console.log("Reply to message:", messageId);
  };

  const handleCloseDropdown = () => {
    setOpenDropdownId(null);
  };

  const currentChat = chats.find((chat) => chat.id === currentChatId);
  const otherParticipant =
    currentChat?.participants?.filter(
      (participant) => participant?.user?.id !== currentUser?.id
    ) || [];

  let otherParticipantName = "";
  if (otherParticipant && otherParticipant.length > 1) {
    otherParticipantName = "Group Name";
  } else if (otherParticipant && otherParticipant.length === 1) {
    otherParticipantName = otherParticipant[0]
      ? `${otherParticipant[0].user.firstName || "Unknown"} ${
          otherParticipant[0].user.lastName || "User"
        }`
      : "Unknown User";
  }
  if (currentChat?.name) {
    otherParticipantName = currentChat.name;
  }
  const participantAvatars = currentChat?.participants
    ?.map((participant) => participant?.user?.avatar)
    .filter((avatar) => avatar);

  const getSenderAvatar = (message) => {
    if (message.authorId === currentUser?.id) {
      return currentUser.avatar;
    }
    const sender = otherParticipant.find((p) => p.user.id === message.authorId);
    return sender?.user.avatar || DefaultUserIcon;
  };

  const availableUsers = (chats || [])
    .flatMap((chat) =>
      (chat.participants || []).map((participant) => participant.user)
    )
    .filter(
      (user, index, self) =>
        user.id !== currentUser?.id &&
        !currentChat?.participants?.some(
          (participant) => participant?.user?.id === user.id
        ) &&
        index === self.findIndex((u) => u.id === user.id)
    );

  const formatDate = (dateString) => {
    console.log("DateString ", dateString);
    let d = dateString.split(".")[0];
    console.log(d);
    return moment(d + "Z", "YYYY-MM-DDTHH:mm:ssZ")
      .utc()
      .local()
      .format("hh:mm a");
  };

  const renderUserName = (message) => {
    let user = otherParticipant.find((op) => op.user.id === message.authorId);
    return (
      <span className="text-xs text-black font-bold mr-2">
        {currentUser && message.authorId === currentUser?.id
          ? `${currentUser?.firstName} ${currentUser?.lastName} (You)`
          : `${user && otherParticipant.length >= 1 && user?.user.firstName} ${
              user?.user.lastName
            }`}
      </span>
    );
  };

  const handleRenameChat = useCallback(() => {
    if (newChatName.trim() && currentChatId) {
      dispatch(renameChat(currentChatId, newChatName.trim()));
      setIsRenaming(false);
    } else {
      setNewChatName(otherParticipantName);
      setIsRenaming(false);
    }
  }, [currentChatId, dispatch, newChatName, otherParticipantName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        renameInputRef.current &&
        !renameInputRef.current.contains(event.target)
      ) {
        handleRenameChat();
      }
    };

    if (isRenaming) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isRenaming, handleRenameChat]);

  const handleNameClick = () => {
    if (!currentChat?.programId && otherParticipant.length >= 2) {
      setIsRenaming(true);
      setNewChatName(otherParticipantName);
    }
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const messageDate = moment(message.date)
        .startOf("day")
        .format("YYYY-MM-DD");
      if (!groups[messageDate]) {
        groups[messageDate] = [];
      }
      groups[messageDate].push(message);
      return groups;
    }, {});
  };

  const getMessageDateLabel = (date) => {
    const today = moment().startOf("day");
    const messageDay = moment(date).startOf("day");
    if (messageDay.isSame(today, "day")) {
      return "Today";
    } else if (messageDay.isSame(today.subtract(1, "day"), "day")) {
      return "Yesterday";
    } else if (messageDay.isSame(today, "week")) {
      return messageDay.format("dddd");
    } else {
      return messageDay.format("MMMM DD, YYYY");
    }
  };

  useEffect(() => {
    if (messages.length) {
      const groupedMessages = groupMessagesByDate(messages);
      // Only update if groups are actually different
      if (JSON.stringify(messageGroups) !== JSON.stringify(groupedMessages)) {
        setMessageGroups(groupedMessages);
      }
    }
  }, [messages, currentChatId]);

  const handlePinnedMessageClick = (messageId) => {
    const messageElement = messageRefs.current[messageId];
    if (messageElement) {
      messageElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setHighlightedMessageId(messageId);

      setTimeout(() => setHighlightedMessageId(null), 2000);
    }
  };

  const renderPinnedMessages = () => {
    const pinnedMessages = messages.filter((message) => message.pinned);
    if (pinnedMessages.length === 0) return null;

    return (
      <PinnedMessagesDropdown
        messages={pinnedMessages}
        onPinnedMessageClick={handlePinnedMessageClick}
        getSenderAvatar={getSenderAvatar}
        renderUserName={renderUserName}
        formatDate={formatDate}
        handleUnpinMessage={handleUnpinMessage}
        DefaultUserIcon={DefaultUserIcon}
      />
    );
  };

  const renderForwardDialog = () => {
    // Close Modal and Reset States
    const handleClose = () => {
      setForwardDialogOpen(false);
      setSearchInputValue("");
      setForwardParticipant([]);
    };

    // Render User Item
    const UserItem = ({ user, onClick, actionIcon }) => (
      <div
        key={user.id}
        className="flex items-center justify-between p-2 hover:bg-gray-100 rounded-lg cursor-pointer"
        onClick={onClick}
      >
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full overflow-hidden">
            <AuthImage
              imageurl={user.avatar || DefaultUserIcon}
              className="w-full h-full object-cover"
            />
          </div>
          <span className="ml-2">{`${user.firstName} ${user.lastName}`}</span>
        </div>
        <img src={actionIcon} alt="action-icon" className="w-3 h-3" />
      </div>
    );

    return (
      <Modal
        open={forwardDialogOpen}
        onClose={handleClose}
        aria-labelledby="forward-message-title"
        aria-describedby="forward-message-description"
      >
        <div className="p-4 bg-white rounded-lg shadow-lg w-full max-w-md mx-auto">
          {/* Header */}
          <h2 id="forward-message-title" className="text-lg font-bold mb-4">
            Forward Message
          </h2>

          {/* Search Input */}
          <div className="relative mb-4">
            <input
              value={searchInputValue}
              onChange={handleSearchInputChange}
              className="py-2 px-8 w-full outline-none rounded-lg bg-gray-100"
              placeholder="Search User"
              aria-label="Search user to forward message"
            />
            <img
              className="absolute w-4 left-3 top-2.5"
              src={SearchIcon}
              alt="search-icon"
            />
          </div>

          {/* Selected Users */}
          {forwardParticipant.length > 0 && (
            <div className="mb-4">
              <span className="ml-2 text-sm font-semibold">Selected Users</span>
              <div className="mt-2 space-y-2">
                {forwardParticipant.map((user) => (
                  <UserItem
                    key={user.id}
                    user={user}
                    onClick={() => handleRemoveForwardParticipant(user)}
                    actionIcon={FaTimes}
                  />
                ))}
              </div>
            </div>
          )}

          {/* User List */}
          <span className="ml-2 text-sm font-semibold">
            Select or Search Users
          </span>
          <div className="user-list max-h-60 overflow-y-auto mt-2">
            {chatSearchedUsers.length > 0 ? (
              chatSearchedUsers
                .filter(
                  (user) =>
                    user.id !== currentUser?.id && // Exclude current user
                    !currentChat?.participants?.some(
                      (participant) => participant.user.id === user.id // Exclude current chat participants
                    ) &&
                    !forwardParticipant.some(
                      (participant) => participant.id === user.id // Exclude already selected users
                    )
                )
                .map((user) => (
                  <UserItem
                    key={user.id}
                    user={user}
                    onClick={() => handleForwardParticipant(user)}
                    actionIcon={PlusIcon}
                  />
                ))
            ) : availableUsers.length > 0 ? (
              availableUsers
                .filter(
                  (user) =>
                    !forwardParticipant.some(
                      (participant) => participant.id === user.id
                    )
                )
                .map((user) => (
                  <UserItem
                    key={user.id}
                    user={user}
                    onClick={() => handleForwardParticipant(user)}
                    actionIcon={PlusIcon}
                  />
                ))
            ) : (
              <p className="text-sm text-gray-500 text-center py-4">
                No users available to forward.
              </p>
            )}
          </div>

          {/* Footer */}
          <div className="mt-4 flex justify-end space-x-2">
            <button
              className="px-4 py-2 bg-gray-300 rounded-md"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className={`px-4 py-2 rounded-md text-white ${
                forwardParticipant.length < 1
                  ? "bg-blue-300 cursor-not-allowed"
                  : "bg-blue-500"
              }`}
              onClick={handleForwardSubmit}
              disabled={forwardParticipant.length < 1}
            >
              Forward
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div
      className="chatting-area"
      style={{
        height: "calc(100vh - 120px)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {currentChatId ? (
        <>
          <div className="chat-header flex items-center justify-between py-4 px-6">
            <div className="flex items-center">
              <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                <AuthImage
                  imageurl={
                    (otherParticipant &&
                      otherParticipant.length === 1 &&
                      otherParticipant[0]?.user.avatar) ||
                    DefaultUserIcon
                  }
                  className="w-full h-full object-cover"
                  maxWidth="50px"
                  maxHeight="50px"
                />
              </div>
              <div className="ml-3">
                {isRenaming ? (
                  <div ref={renameInputRef}>
                    <input
                      type="text"
                      value={newChatName}
                      onChange={(e) => setNewChatName(e.target.value)}
                      className="border-b border-gray-300 focus:border-[#5051F9] outline-none px-2 py-1 text-xl font-semibold bg-transparent"
                      autoFocus
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleRenameChat();
                        }
                      }}
                    />
                  </div>
                ) : (
                  <h2
                    className={`text-xl font-semibold ${
                      !currentChat?.programId
                        ? "cursor-pointer hover:text-blue-500"
                        : ""
                    }`}
                    onClick={handleNameClick}
                  >
                    {otherParticipantName}
                  </h2>
                )}
                <p className="text-[#5051F9]">
                  {typingStatus && typingStatus.chatId === currentChatId
                    ? "Typing..."
                    : "Online"}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2 relative">
              <div className="flex items-center">
                {participantAvatars?.slice(0, 3).map((avatar, index) => (
                  <div
                    key={index}
                    className={`w-[40px] h-[40px] rounded-full overflow-hidden border-2 border-white bg-white ${
                      index !== 0 ? "-ml-3.5" : ""
                    }`}
                  >
                    <AuthImage
                      imageurl={avatar || DefaultUserIcon}
                      className="w-full h-full object-cover"
                      maxWidth="50px"
                      maxHeight="50px"
                    />
                  </div>
                ))}
                {participantAvatars?.length > 3 && (
                  <div
                    className="w-[40px] h-[40px] rounded-full overflow-hidden border-2 border-white bg-gray-400 text-white flex items-center justify-center cursor-pointer -ml-3.5"
                    onClick={() => setShowModal(true)}
                  >
                    <span className="text-sm font-bold">
                      +{participantAvatars.length - 3}
                    </span>
                  </div>
                )}
              </div>
              {!currentChat?.programId && (
                <img
                  className="w-8 h-8 flex items-center justify-center cursor-pointer"
                  onClick={handleAddParticipantClick}
                  alt="Add Participant"
                  src={AddParticipant}
                />
              )}
              {/* <img className="w-8 h-8" src={ChatLock} alt="Lock Icon" /> */}
              {showAddParticipantPopup && (
                <div
                  ref={popupRef}
                  className="add-participant-popup absolute top-full mt-2 right-0 w-80 bg-white rounded-lg shadow-lg z-10"
                >
                  <h3 className="text-lg font-semibold mb-2 p-4">
                    Add Participant
                  </h3>
                  <div className="relative my-2 px-4">
                    <input
                      value={searchInputValue}
                      onChange={handleSearchInputChange}
                      className="py-2 px-8 w-full outline-none rounded-lg bg-[#FAFAFA]"
                      placeholder="Search User"
                    />
                    <img
                      className="absolute w-[15px] left-[8%] top-[30%]"
                      src={SearchIcon}
                      alt="search-icon"
                    />
                  </div>
                  <div className="user-list max-h-60 overflow-y-auto p-4">
                    {chatSearchedUsers.length > 0
                      ? chatSearchedUsers
                          .filter(
                            (user) =>
                              user.id !== currentUser?.id &&
                              !currentChat?.participants?.some(
                                (participant) => participant.user.id === user.id
                              )
                          )
                          .map((user) => (
                            <div
                              key={user.id}
                              className="flex items-center justify-between p-2 hover:bg-gray-100 rounded-lg cursor-pointer"
                              onClick={() => handleAddParticipant(user)}
                            >
                              <div className="flex items-center">
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                                  <AuthImage
                                    imageurl={user.avatar || DefaultUserIcon}
                                    className="w-full h-full object-cover"
                                    maxWidth="50px"
                                    maxHeight="50px"
                                  />
                                </div>
                                <span className="ml-2">{`${user.firstName} ${user.lastName}`}</span>
                              </div>
                              <img
                                src={PlusIcon}
                                alt="Add"
                                className="w-3 h-3"
                              />
                            </div>
                          ))
                      : availableUsers.map((user) => (
                          <div
                            key={user.id}
                            className="flex items-center justify-between p-2 hover:bg-gray-100 rounded-lg cursor-pointer"
                            onClick={() => handleAddParticipant(user)}
                          >
                            <div className="flex items-center">
                              <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                                <AuthImage
                                  imageurl={user.avatar || DefaultUserIcon}
                                  className="w-full h-full object-cover"
                                  maxWidth="50px"
                                  maxHeight="50px"
                                />
                              </div>
                              <span className="ml-2">{`${user.firstName} ${user.lastName}`}</span>
                            </div>
                            <img src={PlusIcon} alt="Add" className="w-3 h-3" />
                          </div>
                        ))}
                  </div>
                </div>
              )}
              {showModal && (
                <ParticipantsList
                  participants={currentChat?.participants}
                  onClose={() => setShowModal(false)}
                />
              )}
            </div>
          </div>
          {renderPinnedMessages()}

          <div
            ref={chatArea}
            className="chat-messages p-4 flex flex-col flex-grow overflow-auto"
            style={{
              height: "calc(100% - 180px)",
              marginTop: messages.some((m) => m.pinned) ? "0" : undefined,
            }}
          >
            {Object.keys(messageGroups).map((dateKey, index) => (
              <div key={index} className="message-group">
                <div className="sticky-date-header top-0 z-10 text-center py-2">
                  <span className="text-gray-500">
                    {getMessageDateLabel(dateKey)}
                  </span>
                </div>

                {messageGroups[dateKey].map((message) => (
                  <div
                    key={message.id}
                    className={`message-container mb-4 relative transition-all duration-500 ${
                      highlightedMessageId === message.id
                        ? "bg-blue-50 border-2 border-blue-500 rounded-lg"
                        : ""
                    }`}
                    ref={(ref) => addMessageRef(message.id, ref)}
                  >
                    {editingMessageId === message.id ? (
                      <EditMessageComponent
                        editingText={editingText}
                        setEditingText={setEditingText}
                        handleEditSubmit={handleEditSubmit}
                        handleEditCancel={handleEditCancel}
                        messageId={message.id}
                      />
                    ) : (
                      <div
                        className={`message flex items-start ${
                          currentUser && message.authorId === currentUser?.id
                            ? "flex-row-reverse"
                            : ""
                        }`}
                      >
                        <div className="w-[40px] h-[40px] shrink-0 rounded-full overflow-hidden">
                          <AuthImage
                            imageurl={
                              getSenderAvatar(message) || DefaultUserIcon
                            }
                            className="w-full h-full object-cover"
                            maxWidth="50px"
                            maxHeight="50px"
                          />
                        </div>
                        <div className="mx-2 flex flex-col max-w-[60%]">
                          <div className="flex items-center mb-1">
                            <span className="text-xs text-black font-bold mr-2">
                              {renderUserName(message)}
                            </span>
                            <span className="text-xs text-[#7C8B9F]">
                              {formatDate(message.date)}
                            </span>
                          </div>
                          <div
                            className={`message-content p-3 break-words ${
                              currentUser &&
                              message.authorId === currentUser?.id
                                ? "mr-1 bg-[#5051F9] rounded-b-xl rounded-l-xl text-[#FFFFFF]"
                                : "bg-[#ECEFFA] rounded-b-xl rounded-r-xl text-[#768396]"
                            }`}
                          >
                            {message.text && (
                              <p className="whitespace-pre-wrap">
                                {message.text}
                              </p>
                            )}
                            {message.imageUrl && (
                              <AuthImage
                                imageurl={message.imageUrl}
                                className="rounded-lg mt-2 max-w-full h-auto"
                                maxWidth="250px"
                                maxHeight="250px"
                              />
                            )}
                          </div>
                        </div>
                        <div className="relative shrink-0 ml-2">
                          <button
                            className="w-6 h-6 rounded-full hover:bg-gray-100 flex items-center justify-center focus:outline-none transition-colors duration-200"
                            onClick={() =>
                              setOpenDropdownId(
                                openDropdownId === message.id
                                  ? null
                                  : message.id
                              )
                            }
                          >
                            <MoreHorizontal className="w-4 h-4 text-gray-600" />
                          </button>
                          {openDropdownId === message.id && (
                            <MessageActions
                              message={message}
                              currentUser={currentUser}
                              onClose={handleCloseDropdown}
                              onDelete={handleDeleteMessage}
                              onEdit={handleEditMessage}
                              onPin={handlePinMessage}
                              onCopy={handleCopyMessage}
                              onForward={handleForwardMessage}
                              onReply={handleReplyMessage}
                              isEditing={editingMessageId === message.id}
                              onEditSubmit={handleEditSubmit}
                              onEditCancel={handleEditCancel}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            <div ref={hiddenScroll}></div>
          </div>
          <Input
            onSendMessage={handleSendMessage}
            chatId={currentChatId}
            otherParticipants={otherParticipant}
          />
        </>
      ) : (
        <ForumWelcomePlaceholder />
      )}
      {renderForwardDialog()}
    </div>
  );
};

export default ChattingArea;

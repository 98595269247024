import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlannerSidebar from "../components/Planner/PlannerSidebar";
import CalendarEventPlanner from "../components/Planner/CalendarEventPlanner";
import { fetchEvents, fetchPlans } from "../actions/calendarActions";
import DetailsModal from "../components/Planner/DetailsModal";

const Planner = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const onCloseModal = useCallback(() => setShowModal(false), []);
  const [todaysEvent, setTodaysEvent] = useState([]);
  const currentDate = new Date();
  const [view, setView] = useState("month");
  const [initDate, setInitDate] = useState();
  // firstDateOfCurrentMonth.toISOString().split("T")[0]
  const [endDate, setEndDate] = useState();
  // LastDateOfCurrentMonth.toISOString().split("T")[0]
  const events = useSelector((state) => state.calendar.events);
  const plans = useSelector((state) => state.calendar.plans);

  const memoizedSelectedEvent = useMemo(() => selectedEvent, [selectedEvent]);
  console.log(memoizedSelectedEvent);

  useEffect(() => {
    if (initDate) {
      dispatch(fetchEvents(initDate, view, endDate));
    }
  }, [initDate, view]);

  useEffect(() => {
    dispatch(fetchPlans());
  }, [events]);

  const updateEventsAfterSave = () => {
    dispatch(fetchEvents(initDate, view, endDate));
  };

  useEffect(() => {
    const eventlist = events.filter(
      (event) =>
        Date.parse(event?.start) >= Date.parse(currentDate) &&
        Date.parse(event?.end) <= Date.parse(currentDate)
    );
    setTodaysEvent(eventlist);
  }, [events]);

  const handleSetView = useCallback((newView) => {
    if (newView === "dayGridMonth") {
      setView("month");
    } else if (newView === "timeGridWeek") {
      setView("week");
    } else if (newView === "timeGridDay") {
      setView("day");
    }
  }, []);

  const handleDateChange = (date, endDate) => {
    setInitDate(date);
    setEndDate(endDate);
  };
  return (
    <div className="grid mt-4 mx-3 grid-cols-3 grid-flow-col">
      <PlannerSidebar
        setShowModal={setShowModal}
        events={plans}
        setSelectedEvent={setSelectedEvent}
      />
      <CalendarEventPlanner
        events={events}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        setShowModal={setShowModal}
        view={view}
        handleSetView={handleSetView}
        handleDateChange={handleDateChange}
      />
      <DetailsModal
        showModal={showModal}
        setShowModal={setShowModal}
        onCloseModal={onCloseModal}
        event={memoizedSelectedEvent}
        updateEventsAfterSave={updateEventsAfterSave}
      />
    </div>
  );
};

export default React.memo(Planner);

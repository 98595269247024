import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Pagination,
  Skeleton,
  Dropdown,
  Form,
  message,
  Alert,
} from "antd";
import {
  FaRupeeSign,
  FaEllipsisV,
  FaEdit,
  FaTrash,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import { SearchOutlined, EllipsisOutlined } from "@ant-design/icons";
import { get } from "../../util/APIUtils";
import { useDispatch, useSelector } from "react-redux";
import ContentImg from "../../assets/imagePlaceholder.png";
import { BiPlus } from "react-icons/bi";
import ProgramModal from "../../components/AcademicProgram/ProgramModal";
import {
  fetchPrograms,
  saveProgram,
  updateProgram,
  deleteProgram,
} from "../../actions/academicProgramActions";
import { useNavigate } from "react-router-dom";

// Sample data structure with userRole
const organizationsData = [
  {
    id: 1,
    name: "University of Excellence",
    price: 50000,
    programs: [
      {
        id: 1,
        name: "Computer Science",
        date: "2023-09-01",
        image: "/placeholder.svg?height=100&width=150",
        isPublished: true,
      },
      {
        id: 2,
        name: "Electrical Engineering",
        date: "2023-09-15",
        image: null,
        isPublished: true,
      },
      {
        id: 3,
        name: "Mechanical Engineering",
        date: "2023-09-10",
        image: "/placeholder.svg?height=100&width=150",
        isPublished: false,
      },
      {
        id: 4,
        name: "Civil Engineering",
        date: "2023-09-05",
        image: null,
        isPublished: true,
      },
      {
        id: 5,
        name: "Chemical Engineering",
        date: "2023-09-20",
        image: "/placeholder.svg?height=100&width=150",
        isPublished: false,
      },
    ],
  },
  {
    id: 2,
    name: "Institute of Technology",
    price: 60000,
    programs: [
      {
        id: 6,
        name: "Data Science",
        date: "2023-08-01",
        image: "/placeholder.svg?height=100&width=150",
        isPublished: true,
      },
      {
        id: 7,
        name: "Artificial Intelligence",
        date: "2023-08-15",
        image: null,
        isPublished: true,
      },
      {
        id: 8,
        name: "Robotics",
        date: "2023-08-10",
        image: "/placeholder.svg?height=100&width=150",
        isPublished: false,
      },
      {
        id: 9,
        name: "Biotechnology",
        date: "2023-08-05",
        image: null,
        isPublished: true,
      },
      {
        id: 10,
        name: "Nanotechnology",
        date: "2023-08-20",
        image: "/placeholder.svg?height=100&width=150",
        isPublished: false,
      },
    ],
  },
];



const AcademicProgramCard = ({ program, market }) => {
  const navigate = useNavigate();
  const goToDetails = (p) => {
    if (market) {
      navigate(`/marketplace/${p.id}`);
    } else {
      navigate(`/acad-programs/${p.id}`);
    }
  };
  const menuItems = [
    { key: "1", icon: <FaEdit />, label: "Edit" },
    { key: "2", icon: <FaTrash />, label: "Delete" },
    {
      key: "3",
      icon: program.isPublished ? <FaToggleOff /> : <FaToggleOn />,
      label: program.isPublished ? "Unpublish" : "Publish",
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex flex-col transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg relative" onClick={()=>goToDetails(program)}>
      <img
        src={program.image || ContentImg}
        alt={program.name}
        className="w-full h-24 object-cover rounded-md mb-2"
      />
      <h3 className="text-lg font-semibold">{program.programName}</h3>
      <p
        className="text-sm text-gray-600 rte whitespace-normal text-ellipsis"
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
        }}
        dangerouslySetInnerHTML={{ __html: program.description }}
      ></p>
      {!market && (
        <div className="absolute top-2 right-2">
          <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
            <Button icon={<EllipsisOutlined />} size="small" />
          </Dropdown>
        </div>
      )}
    </div>
  );
};

const OrganizationSection = ({ organization, market }) => {
  const [showAll, setShowAll] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  // const displayedPrograms = showAll
  //   ? organization.programs
  //   : organization.programs.slice(0, 4);

  const fetchPrograms = async (organizationId, page = 0, size = 4) => {
    try {
      setLoading(true);
      const response = await get(
        `/organization-service/api/academicPrograms/search/findByOrgId?organizationId=${organizationId}&page=${page}&size=${size}`
      );
      setPrograms(response.content || []);
    } catch (error) {
      console.error("Error fetching programs:", error);
    } finally {
      setLoading(false);
    }
  };
  // console.log("Test---->>>", organization, organization.id);
  useEffect(() => {
    fetchPrograms(organization.id);
  }, [organization.id]);

  return (
    <div className="bg-gray-100 rounded-lg p-6 mb-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{organization.name}</h2>
        {market ? (
          <>
            {programs.length > 0 && (
              <Button type="primary" className="flex items-center">
                Buy For <FaRupeeSign className="ml-1" />
                {/* {organization.price.toLocaleString("en-IN")} */}
              </Button>
            )}
          </>
        ) : (
          <div className="flex items-center">
            <FaRupeeSign className="text-xl mr-1" />
            <span className="text-xl font-semibold">
              {/* {organization.price.toLocaleString("en-IN")} */}
            </span>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
        {programs.length > 0 ? (
          <>
            {programs.map((program) => (
              <AcademicProgramCard
                key={program.id}
                program={program}
                market={market}
                // userRole={userRole}
              />
            ))}
          </>
        ) : (
          <>
            <h3 className="text-lg font-semibold">No Programs</h3>
          </>
        )}
      </div>
      {/* {organization.programs.length > 4 && (
        <div className="text-right">
          <Button type="primary" onClick={() => setShowAll(!showAll)}>
            {showAll ? "Show Less" : "Show More"}
          </Button>
        </div>
      )} */}
    </div>
  );
};

const Programs = (props) => {
  const [loading, setLoading] = useState(true);
  const { market } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useSelector((state) => state.users.currentUser);
  const [paginatedOrganizations, setPaginatedOrganizations] = useState([]);
  // const [userRole, setUserRole] = useState("student");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false);
  const [program, setProgram] = useState({
    id: "",
    programName: "",
    description: "",
    duration: "",
    level: "",
    mode: "",
    type: "",
    department: "",
    faculty: "",
    university: "",
    country: "",
    city: "",
    website: "",
    email: "",
    phone: "",
    address: "",
    postalCode: "",
    state: "",
    countryId: "",
    cityId: "",
    departmentId: "",
    facultyId: "",
    universityId: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    createdBy: "",
    updatedBy: "",
    deletedBy: "",
    status: "",
    isDeleted: false,
    isPublished: false,
    isFeatured: false,
    isVerified: false,
    isApproved: false,
    isRejected: false,
    isPending: false,
    isDraft: false,
    isArchived: false,
    isBlocked: false,
    isSpam: false,
    isFlagged: false,
    isReported: false,
    isClosed: false,
    isBanned: false,
    isHidden: false,
    isPublic: false,
    isPrivate: false,
    isProtected: false,
    isPaid: false,
    isFree: false,
    isFeaturedHome: false,
    isFeaturedCategory: false,
    isFeaturedSubCategory: false,
    isFeaturedPage: false,
    isFeaturedPost: false,
    isFeaturedEvent: false,
    isFeaturedCourse: false,
    isFeaturedInternship: false,
    schedules: [],
  });

  const [selectedProgram, setSelectedProgram] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const pageSize = 20; // Number of organizations per page
  const dispatch = useDispatch();
  const { programs, pageable } = useSelector((state) => state.academicPrograms);

  const handleEditorChange = (data) => {
    setProgram((prevState) => ({
      ...prevState,
      description: data,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProgram((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file;
        const base64 = await getBase64(file);
        return {
          default: base64,
        };
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (program.id) {
      dispatch(updateProgram(program.id, program)).then(() => {
        message.success("Program updated successfully");
        setShowCreateProgramModal(false);
        // setCurrentStep(0);
        dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
        // form.resetFields();
      });
    } else {
      if (!program.programName) {
        return Alert("Please enter program title");
      }
      dispatch(saveProgram(program)).then(() => {
        message.success("Program saved successfully");
        setShowCreateProgramModal(false);
        // setCurrentStep(0);
        dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
        // form.resetFields();
      });
    }
    // setProgram({
    //   id: "",
    //   programName: "",
    //   description: "",
    //   duration: "",
    //   level: "",
    //   mode: "",
    //   type: "",
    //   department: "",
    //   faculty: "",
    //   university: "",
    //   country: "",
    //   city: "",
    //   website: "",
    //   email: "",
    //   phone: undefined,
    //   address: "",
    //   postalCode: "",
    //   state: "",
    //   countryId: "",
    //   cityId: "",
    //   departmentId: "",
    //   facultyId: "",
    //   universityId: "",
    //   createdAt: "",
    //   updatedAt: "",
    //   deletedAt: "",
    //   createdBy: "",
    //   updatedBy: "",
    //   deletedBy: "",
    //   status: "",
    //   isDeleted: false,
    //   isPublished: false,
    //   isFeatured: false,
    //   isVerified: false,
    //   isApproved: false,
    //   isRejected: false,
    //   isPending: false,
    //   isDraft: false,
    //   isArchived: false,
    //   isBlocked: false,
    //   isSpam: false,
    //   isFlagged: false,
    //   isReported: false,
    //   isClosed: false,
    //   isBanned: false,
    //   isHidden: false,
    //   isPublic: false,
    //   isPrivate: false,
    //   isProtected: false,
    //   isPaid: false,
    //   isFree: false,
    //   isFeaturedHome: false,
    //   isFeaturedCategory: false,
    //   isFeaturedSubCategory: false,
    //   isFeaturedPage: false,
    //   isFeaturedPost: false,
    //   isFeaturedEvent: false,
    //   isFeaturedCourse: false,
    //   isFeaturedInternship: false,
    //   schedules: [],
    // });
  };

  const handleEdit = (e, program) => {
    e.stopPropagation();
    setProgram(program);
    setShowCreateProgramModal(true);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    dispatch(deleteProgram(id)).then(() => {
      Alert("Program deleted successfully");
      dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
    });
  };

  useEffect(() => {
    // Simulate loading
    setTimeout(() => setLoading(false), 1500);
  }, []);

  const filteredOrganizations = organizationsData.filter(
    (org) =>
      org.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      org.P.some((program) =>
        program.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  // const paginatedOrganizations = filteredOrganizations.slice(
  //   (currentPage - 1) * pageSize,
  //   currentPage * pageSize
  // );

  const fetchOrganisations = async (orgId, page, pageSize = 20) => {
    try {
      const url = market
        ? `/organization-service/api/organizations?page=${
            page - 1
          }&size=${pageSize}`
        : `/organization-service/api/organizations/${orgId}`;

      const response = await get(url);

      // Assuming the response contains paginated data
      // const { data, total } = response;

      // Set state for paginated organizations and total records
      if (response) {
        setPaginatedOrganizations(market ? response.content : [response]);
        setTotalRecords(market ? response.totalElements : 1);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  useEffect(() => {
    if (
      !market &&
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      fetchOrganisations(currentUser.organization.id);
    } else {
      fetchOrganisations(currentUser.organization, currentPage, pageSize);
    }
  }, [currentUser, market, currentPage]);

  const createProgram = () => {
    setProgram({});
    setShowCreateProgramModal(true);
  };

  return (
    <div className="container mx-auto px-4 py-8 ">
      <div className="flow-root flex items-center">
        <h2 className="float-left text-2xl font-bold mb-4 flex justify-between">
          {market ? "Market" : "Academic Programs"}
        </h2>
        {!market && (
          <div className="float-right h-fit min-h-full flex justify-end">
            <button
              onClick={createProgram}
              className="p-2 bg-blue-600 text-white rounded flex items-center"
            >
              <BiPlus size={20} className="mr-2" /> Create program
            </button>
          </div>
        )}
      </div>

      <div className="mb-6">
        <Input
          placeholder="Search programs or organizations"
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full max-w-md mx-auto"
        />
      </div>

      {/* Role selection for demonstration purposes */}
      {/* <div className="mb-6 text-center">
        <span className="mr-2">Select Role:</span>
        <Button
          onClick={() => setUserRole("student")}
          type={userRole === "student" ? "primary" : "default"}
        >
          Student
        </Button>
        <Button
          onClick={() => setUserRole("organisation_admin")}
          type={userRole === "organisation_admin" ? "primary" : "default"}
          className="mx-2"
        >
          Admin
        </Button>
        <Button
          onClick={() => setUserRole("role_teacher")}
          type={userRole === "role_teacher" ? "primary" : "default"}
        >
          Teacher
        </Button>
      </div> */}

      {loading ? (
        <div
          className="space-y-6"
          style={{
            height: "calc(100vh - 350px)",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {[...Array(2)].map((_, index) => (
            <div
              key={index}
              style={{
                // height: "calc(100vh - 350px)",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Skeleton.Input
                active
                style={{
                  width: "65vw",
                  height: "20vh",
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div
          className="overflow-y-auto "
          style={{ height: "calc(100vh - 350px)" }}
        >
          {paginatedOrganizations.map((org) => (
            <OrganizationSection
              key={org.id}
              organization={org}
              // userRole={userRole}
              market={market}
            />
          ))}
        </div>
      )}

      <div className="mt-8 flex justify-center">
        <Pagination
          current={currentPage}
          total={totalRecords}
          pageSize={pageSize}
          onChange={(page) => {
            setCurrentPage(page);
            fetchOrganisations(null, page, pageSize); // Pass current page to the function
          }}
          showSizeChanger={false}
        />
      </div>
      <ProgramModal
        showCreateProgramModal={showCreateProgramModal}
        setShowCreateProgramModal={setShowCreateProgramModal}
        program={program}
        setProgram={setProgram}
        handleChange={handleChange}
        handleEditorChange={handleEditorChange}
        uploadPlugin={uploadPlugin}
        handleSave={handleSave}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        form={form}
        Form={Form}
      />
    </div>
  );
};

export default Programs;

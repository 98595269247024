import React, { useState } from "react";
import { Pin } from "lucide-react";
import AuthImage from "../AuthImage";

const PinnedMessagesPopup = ({
  messages,
  onPinnedMessageClick,
  getSenderAvatar,
  renderUserName,
  formatDate,
  handleUnpinMessage,
  DefaultUserIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (messages.length === 0) return null;

  return (
    <div className="relative">
      {/* Header */}
      <div
        className="px-4 py-2 flex items-center justify-between hover:bg-gray-50 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <Pin className="w-4 h-4 text-blue-600 mr-2" />
          <span className="text-sm font-medium text-gray-600">
            Pinned Messages ({messages.length})
          </span>
        </div>
      </div>

      {/* Popup */}
      {isOpen && (
        <>
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black/20"
            onClick={() => setIsOpen(false)}
          />

          {/* Popup Content */}
          <div className="absolute top-full left-0 right-0 bg-white rounded-lg shadow-lg max-h-96 overflow-hidden z-50 mx-4 my-2">
            {/* Popup Header */}
            <div className="px-4 py-3 border-b">
              <h3 className="text-base font-semibold text-gray-900">
                Pinned Messages ({messages.length})
              </h3>
            </div>

            {/* Messages List */}
            <div className="overflow-y-auto max-h-[calc(100vh-200px)]">
              {messages.map((message) => (
                <div
                  key={`pinned-${message.id}`}
                  className="px-4 py-3 hover:bg-gray-50 border-b last:border-b-0 cursor-pointer p-2 rounded-lg transition-colors"
                  onClick={() => {
                    setIsOpen(!isOpen);
                    onPinnedMessageClick(message.id);
                  }}
                >
                  <div className="flex items-start space-x-3">
                    <div className="w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
                      <AuthImage
                        imageurl={getSenderAvatar(message) || DefaultUserIcon}
                        className="w-full h-full object-cover"
                        maxWidth="32px"
                        maxHeight="32px"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <span className="font-medium text-sm">
                            {renderUserName(message)}
                          </span>
                          <span className="text-xs text-gray-500">
                            {formatDate(message.date)}
                          </span>
                        </div>
                        <button
                          className="p-1 hover:bg-gray-200 rounded-full"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUnpinMessage(message.id);
                          }}
                          title="Unpin message"
                        >
                          <Pin className="w-4 h-4 text-gray-400" />
                        </button>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">
                        {message.text}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PinnedMessagesPopup;

import React, { useState, useEffect, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { BiTime, BiX } from "react-icons/bi";
import moment from "moment";
import { Tooltip } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import "./CalendarEventPlanner.css";

const CalendarEventPlanner = ({
  events,
  selectedEvent,
  setSelectedEvent,
  setShowModal,
  view,
  handleSetView,
  handleDateChange,
}) => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  const mappedEvents = useMemo(() => {
    return events.map((event) => ({
      id: event.id,
      title: event.title,
      start: event.startDate
        ? moment(event.startDate).utc().local().toDate()
        : "",
      end: event.endDate ? moment(event.endDate).utc().local().toDate() : "",
      color: `${event.colorCode}9A`,
      backgroundColor: `${event.colorCode}9A`,
      textColor: "#fff",
      summary: event.summary,
      eventType: event.eventType,
      colorCode: event.colorCode,
      recurrenceType: event.recurrenceType,
      seriesId: event.seriesId,
    }));
  }, [events]);

  const handleEventSelection = (event) => {
    // If it's a FullCalendar Event object
    if (event.toPlainObject) {
      setSelectedEvent({
        id: event.id,
        title: event.title,
        startDate: event.start,
        endDate: event.end,
        colorCode:
          event.extendedProps.colorCode || event.backgroundColor?.slice(0, -2),
        eventType: event.extendedProps.eventType,
        summary: event.extendedProps.summary,
        recurrenceType: event.extendedProps.recurrenceType,
        seriesId: event.extendedProps.seriesId,
      });
    } else {
      // If it's mapped event object from slider
      setSelectedEvent({
        id: event.id,
        title: event.title,
        startDate: event.start,
        endDate: event.end,
        colorCode: event.colorCode || event.color?.slice(0, -2),
        eventType: event.eventType,
        summary: event.summary,
        recurrenceType: event.recurrenceType,
        seriesId: event.seriesId,
      });
    }
    setShowModal(true);
  };

  const handleMoreEventsClick = (info) => {
    const date = info.date;
    const dayStart = moment(date).startOf("day");
    const dayEnd = moment(date).endOf("day");

    const dayEvents = mappedEvents.filter((event) => {
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
      return (
        eventStart.isBetween(dayStart, dayEnd, "day", "[]") ||
        eventEnd.isBetween(dayStart, dayEnd, "day", "[]")
      );
    });

    dayEvents.sort(
      (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()
    );

    setSelectedDateEvents(dayEvents);
    setSelectedDate(date);
    setIsSliderOpen(true);

    return true;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const slider = document.getElementById("event-slider");
      if (slider && !slider.contains(event.target) && isSliderOpen) {
        setIsSliderOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isSliderOpen]);

  const renderEventContent = (eventInfo) => {
    if (eventInfo.view.type === "dayGridMonth") {
      return (
        <Tooltip title={`${eventInfo.event.title} - ${eventInfo.timeText}`}>
          <div
            className="fc-event-content"
            style={{
              backgroundColor: eventInfo.backgroundColor,
              width: 4,
              height: 4,
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleEventSelection(eventInfo.event);
            }}
          >
            <div className="fc-event-title">{eventInfo.event.title}</div>
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={`${eventInfo.event.title} - ${eventInfo.timeText}`}>
        <div
          className="fc-event-content"
          style={{ padding: 5 }}
          onClick={(e) => {
            e.stopPropagation();
            handleEventSelection(eventInfo.event);
          }}
        >
          <div
            className="fc-event-title"
            style={{
              marginBottom: 5,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {eventInfo.event.title}
          </div>
          <hr />
          <div className="fc-event-title">
            {eventInfo.view.type === "timeGridDay" && (
              <span
                style={{ display: "inline-block", padding: 5, paddingLeft: 0 }}
              >
                <BiTime color="white" size={19} />
              </span>
            )}
            <span
              style={{
                display: "inline-block",
                padding: 5,
                verticalAlign: "top",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {eventInfo.timeText}
            </span>
          </div>
        </div>
      </Tooltip>
    );
  };

  const handleDatesSet = (dateInfo) => {
    const { start, end, view: currentView } = dateInfo;
    handleSetView(currentView.type);
    handleDateChange(
      start.toISOString().split("T")[0],
      end.toISOString().split("T")[0]
    );
  };

  let scrollTime = moment().format("HH") + ":00:00";

  return (
    <div
      className="myplanner col-span-3 bg-white py-3 overflow-y-scroll px-4 ml-3 rounded-lg relative"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="h-[75vh] calWrapper">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          height="82vh"
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,today,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          titleFormat={{ year: "numeric", month: "short" }}
          events={mappedEvents}
          eventContent={renderEventContent}
          dayMaxEventRows={5}
          moreLinkClick={handleMoreEventsClick}
          displayEventTime={view === "timeGridDay" || view === "timeGridWeek"}
          slotEventOverlap={view === "timeGridWeek"}
          eventMaxStack={view === "timeGridWeek" ? 1 : 4}
          eventDisplay="list-item"
          nowIndicator={true}
          eventClick={(info) => handleEventSelection(info.event)}
          datesSet={handleDatesSet}
          scrollTime={scrollTime}
        />

        <div
          id="event-slider"
          className={`fixed top-0 right-0 h-full w-96 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
            isSliderOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex justify-between items-center px-6 py-4 border-b">
            <h2 className="text-lg font-medium text-gray-800">
              {selectedDate && moment(selectedDate).format("MMMM D, YYYY")}
            </h2>
            <button
              onClick={() => setIsSliderOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <BiX size={24} />
            </button>
          </div>

          <div className="overflow-y-auto h-[calc(100%-64px)]">
            {selectedDateEvents.map((event) => (
              <div
                key={event.id}
                className="px-6 py-3 border-b border-gray-100 hover:bg-gray-50 cursor-pointer"
                onClick={() => {
                  handleEventSelection(event);
                  setIsSliderOpen(false);
                }}
              >
                <div className="flex items-start">
                  <div
                    className="w-2 h-2 rounded-full mt-2 mr-3 flex-shrink-0"
                    style={{ backgroundColor: event.color }}
                  />
                  <div className="flex-grow">
                    <h3 className="font-medium text-gray-800">{event.title}</h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {moment(event.start).format("h:mm A")} -{" "}
                      {moment(event.end).format("h:mm A")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {isSliderOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-25 z-40"
            onClick={() => setIsSliderOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default CalendarEventPlanner;

import React, { useState, useEffect } from "react";
import moment from "moment";
import { Collapse, Pagination, Spin, message } from "antd";
import { EVENT_TYPES } from "../../constantsNew";
import EventStatus from "./EventStatus";
import EventCard from "./EventCard";
import { get } from "../../util/APIUtils";

const { Panel } = Collapse;

const PlannerSidebar = ({ events, setShowModal, setSelectedEvent }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pastEvents, setPastEvents] = useState([]);
  const [totalPastEvents, setTotalPastEvents] = useState(0);
  const [loading, setLoading] = useState(false);

  const pageSize = 20;

  const fetchPastEvents = async () => {
    setLoading(true);
    try {
      const response = await get(
        `/calendar-service/api/plans/pastPlans?page=${currentPage}&size=${pageSize}`
      );
      setPastEvents(response?.data);
      setTotalPastEvents(response?.page?.totalElements);
    } catch (error) {
      message.error("Failed to fetch past events. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSetEvent = (event) => {
    console.log("Selected Event===>", event);
    setSelectedEvent({
      ...event,
    });
  };

  const formatTime = (timestamp) => moment(timestamp).format("h:mm A");

  const onPageChange = (page) => {
    setCurrentPage(page);
    fetchPastEvents(page);
  };

  useEffect(() => {
    fetchPastEvents(currentPage);
  }, [currentPage]);

  const toggleStatus = (event) => {
    console.log("Updating status of eventId ==>", event);
  };

  useEffect(() => {
    Object.entries(pastEvents)
      .flatMap(([date, events]) =>
        events.map((event) => ({ ...event, formattedDate: date }))
      )
      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
      .map((event, index) => console.log("formatted event--->>>", event));
  }, [pastEvents]);

  return (
    <div
      className="bg-white py-3 px-4 col-span-1 rounded-lg overflow-hidden"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="py-2">
        <h3 className="text-[1.5rem] text-start font-bold">Your Plans</h3>
      </div>
      <div className="overflow-auto h-[90%]">
        <Collapse defaultActiveKey={["2"]}>
          {/* Upcoming Events */}
          <Panel header={`Upcoming Events`} key="1">
            {events && Object.keys(events)?.length > 0 ? (
              Object.keys(events)
                .filter((evnt) => moment(evnt, "DD-MM-YYYY").isAfter(moment())) // Filter future events
                .sort(
                  (a, b) => moment(a, "DD-MM-YYYY") - moment(b, "DD-MM-YYYY") // Ascending order
                )
                .map((evnt, i) => (
                  <>
                    {moment(Object.keys(events)[i], "DD-MM-YYYY").isAfter(
                      moment(),
                      "day"
                    ) && (
                      <>
                        {events[evnt]?.map((event, index) => (
                          <EventCard
                            key={`upcoming-${index}`}
                            event={event}
                            handleSetEvent={handleSetEvent}
                            setShowModal={setShowModal}
                            toggleStatus={toggleStatus}
                          />
                        ))}
                      </>
                    )}
                  </>
                ))
            ) : (
              <div>No Upcoming Events</div>
            )}
          </Panel>

          {/* Today's Agenda */}
          <Panel header={`Today's Agenda (${moment().format("ddd")})`} key="2">
            {events && Object.keys(events)?.length > 0 ? (
              Object.keys(events)
                .sort(
                  (a, b) => moment(b, "DD-MM-YYYY") - moment(a, "DD-MM-YYYY")
                )
                .some((evnts, i) =>
                  moment(Object.keys(events)[i], "DD-MM-YYYY").isSame(
                    moment(),
                    "day"
                  )
                ) ? (
                Object.keys(events)
                  .sort(
                    (a, b) => moment(b, "DD-MM-YYYY") - moment(a, "DD-MM-YYYY")
                  )
                  .map((evnts, i) => (
                    <>
                      {moment(Object.keys(events)[i], "DD-MM-YYYY").isSame(
                        moment(),
                        "day"
                      ) && (
                        <>
                          {events[evnts]?.map((event, index) => (
                            <EventCard
                              key={`upcoming-${index}`}
                              event={event}
                              handleSetEvent={handleSetEvent}
                              setShowModal={setShowModal}
                              toggleStatus={toggleStatus}
                              showCheckBox={true}
                            />
                          ))}
                        </>
                      )}
                    </>
                  ))
              ) : (
                <div>No Agenda for today</div>
              )
            ) : (
              <div>No Agenda for today</div>
            )}
          </Panel>

          {/* Past Events with Pagination */}
          <Panel header="Past Events" key="3">
            {loading ? (
              <Spin tip="Loading past events..." />
            ) : Object.keys(pastEvents).length > 0 ? (
              <>
                {Object.entries(pastEvents)
                  .flatMap(([date, events]) =>
                    events.map((event) => ({ ...event, formattedDate: date }))
                  )
                  // .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map((event, index) => (
                    <EventCard
                      key={`past-${currentPage}-${event.id}`}
                      event={event}
                      handleSetEvent={handleSetEvent}
                      setShowModal={setShowModal}
                      toggleStatus={toggleStatus}
                      showCheckBox={true}
                    />
                  ))}
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalPastEvents}
                  onChange={onPageChange}
                  className="mt-4"
                />
              </>
            ) : (
              <div>No Past Events</div>
            )}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default PlannerSidebar;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Img from "../../assets/icons/gallery.svg";
import Microphone from "../../assets/icons/mic.svg";
import Smile from "../../assets/icons/smile.svg";
import Send from "../../assets/icons/SendMessage.svg";
import Location from "../../assets/icons/location.svg";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { sendTypingStatus, uploadFile } from "../../actions/chatActions";

const Input = ({ onSendMessage, chatId, otherParticipants }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const emojiPickerRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (message && otherParticipants && otherParticipants.length > 0) {
      let messageObj = {
        type: message.length > 0 ? "TypingStatus" : "TypingStatusClear",
        chatId: chatId,
      };
      dispatch(sendTypingStatus(messageObj, otherParticipants));
    } else {
      let messageObj = {
        type: "TypingStatusClear",
        chatId: chatId,
      };
      dispatch(sendTypingStatus(messageObj, otherParticipants));
    }
  }, [message, otherParticipants]);

  const handleSendClick = () => {
    if (message.trim() || uploadedFileId) {
      const imageUrl = uploadedFileId
        ? `/post-service/api/file-uploads/${uploadedFileId}`
        : null;
      onSendMessage(message, imageUrl);
      setMessage("");
      setUploadedFileId(null);
      setFilePreview(null);
      setIsUploading(false);
      setShowEmojiPicker(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        handleSendClick();
      }
    }
  };

  const addEmoji = (emoji) => {
    setMessage(message + emoji.native);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        const response = await dispatch(uploadFile(file));
        setUploadedFileId(response.id);
        setFilePreview(URL.createObjectURL(file));
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleRemoveFile = () => {
    setUploadedFileId(null);
    setFilePreview(null);
    setIsUploading(false);
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !event.target.closest("[data-emoji-toggle]")
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  return (
    <div className="absolute bottom-0 w-full py-4 flex justify-center items-end">
      <div className="relative w-[90%] rounded-full bg-white flex items-end px-4 py-2 shadow-md">
        <img
          className="w-[20px] mx-2 mb-2 cursor-pointer"
          src={Microphone}
          alt="Microphone"
        />
        {(filePreview || isUploading) && (
          <div className="flex items-center justify-center relative w-[50px] h-[50px] mx-2 mb-2">
            {isUploading ? (
              <div className="w-[50px] h-[50px] rounded-md bg-gray-200 animate-pulse">
                <div className="h-full w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 background-animate"></div>
              </div>
            ) : (
              <div className="relative">
                <img
                  src={filePreview}
                  alt="File preview"
                  className="object-cover rounded-md w-[50px] h-[50px]"
                />
                <button
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
                  onClick={handleRemoveFile}
                >
                  X
                </button>
              </div>
            )}
          </div>
        )}
        <textarea
          ref={textareaRef}
          className="flex-grow outline-none rounded-lg px-4 py-2 resize-none min-h-[24px] max-h-[120px] overflow-y-auto"
          placeholder="Add a comment..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          rows={1}
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#cbd5e1 transparent",
          }}
        />
        <div className="flex items-center gap-3 mb-2">
          <input
            type="file"
            style={{ display: "none" }}
            id="file"
            className="w-[15px]"
            onChange={handleFileUpload}
            accept="image/*"
          />
          <label htmlFor="file">
            <img
              className="w-[20px] cursor-pointer"
              src={Img}
              alt="Attach File"
            />
          </label>
          <img
            className={`w-[20px] mx-2 cursor-pointer transition-transform ${
              showEmojiPicker ? "rotate-180" : ""
            }`}
            src={Smile}
            alt="Emojis"
            onClick={toggleEmojiPicker}
            data-emoji-toggle
          />
          {showEmojiPicker && (
            <div
              ref={emojiPickerRef}
              className="emoji-picker absolute bottom-full mb-2"
              style={{
                zIndex: 1000,
                position: "absolute",
                bottom: "100%",
                right: "0",
                marginBottom: "8px",
              }}
            >
              <Picker data={data} onEmojiSelect={addEmoji} />
            </div>
          )}
          {/* <img className="w-[20px] mx-2" src={Location} alt="Location" />
          <img
            className="w-[20px] mx-2"
            src={Send}
            alt="Send"
            onClick={handleSendClick}
            style={{ cursor: "pointer" }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Input;
